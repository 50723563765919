import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';
import DefaultLayout from "./components/app";
import Login from './components/auth/login';
import ResetPassword from './components/auth/resetPassword';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import ForgetPassword from './components/auth/forgetPassword';


const App = (props) => {

    const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

    useEffect(() => {
        const layout = localStorage.getItem('layout_version')
        document.body.classList.add(layout);
    }, []);

  return (
    <Provider store={store}>
        <BrowserRouter>
            <React.Suspense fallback={loading()}>
                <Switch>
                    <PublicRoute {...props} path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                    <PublicRoute {...props} path={`${process.env.PUBLIC_URL}/reset-password`} component={ResetPassword} />
                    <PublicRoute {...props} path={`${process.env.PUBLIC_URL}/forget-password`} component={ForgetPassword} />
                    <PrivateRoute {...props} path={`${process.env.PUBLIC_URL}/`} component={DefaultLayout} />
                </Switch>
            </React.Suspense>
        </BrowserRouter>
    </Provider>
  );
}

export default App;