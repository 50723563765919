import React, { useState, useEffect } from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import AuthService from "../services/AuthService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./common/loader";
import { Switch, Route } from "react-router-dom";
import ClientProvider from "../providers/clientProvider";

import loaderRoutes from "../routes";
import loaderNavbar from "../navbar";

const AppLayout = props => {
  const [routes, setRoutes] = useState([]);
  const [menuNavbar, setMenuNavbar] = useState([]);

  useEffect(() => {
    async function anyNameFunction() {
      if (AuthService.isAuthenticated()) {
        let client = null;

        if (AuthService.isClient()) {
          client = await ClientProvider.get(
            AuthService.getUser().extended_role_id
          );
        }

        setRoutes(await loaderRoutes.load(client));
        setMenuNavbar(await loaderNavbar.load(client));
      }
    }

    anyNameFunction();
  }, []);

  return (
    <div>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          <Sidebar Navbar={menuNavbar} />
          <RightSidebar />
          <div className="page-body">
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => <route.component {...props} />}
                  />
                ) : null;
              })}
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AppLayout;
