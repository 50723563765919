import API from './ApiService';
import ApiResponseService from './ApiResponseService';
import jwt_decode from 'jwt-decode';

const api = API.create(process.env.REACT_APP_API_URL);

const AuthService = {
  login: async (email, password) => {
    try {
      const response = await api.login(email, password)

      if (!response.ok) {
        throw new ApiResponseService(response);
      }

      localStorage.setItem('token', response.data.token);

      return {
        loggedIn: true,
      };

    } catch (error) {
      return {
        error: error,
        loggedIn: false,
      };
    }
  },
  logout: async () => {
    try {
      localStorage.clear();
      return {
        logout: true,
      };
    } catch (error) {
      return {
        error: error,
        logout: false,
      };
    }
  },
  isAuthenticated: () => {
    const token = localStorage.getItem('token');

    let isAuth = true;

    if (!token) {
      isAuth = false;
    }

    return isAuth;
  },
  getRole: () => {
    const token = localStorage.getItem('token');

    var decoded = jwt_decode(token);

    return decoded.user.role.name;
  },
  getRoleName: () => {
    const token = localStorage.getItem('token');

    var decoded = jwt_decode(token);

    return decoded.user.role.description;
  },
  isProvider: () => {
    const token = localStorage.getItem('token');

    var decoded = jwt_decode(token);

    return decoded.user.role.name === 'provider'
  },
  isInternalProviderAdministrator: () => {
    const token = localStorage.getItem('token');

    var decoded = jwt_decode(token);

    return decoded.user.role.name === 'internal_provider_administrator';
  },
  isFiscaliaProvider: () => {
    const token = localStorage.getItem('token');

    var decoded = jwt_decode(token);

    return decoded.user.role.name === 'provider' && decoded.user.provider_type && decoded.user.provider_type === 'fiscalia';
  },
  isClient: () => {
    const token = localStorage.getItem('token');

    var decoded = jwt_decode(token);

    return decoded.user.role.name === 'client'
  },
  isAdministrator: () => {
    const token = localStorage.getItem('token');

    var decoded = jwt_decode(token);

    return decoded.user.role.name === 'administrator'
  },
  getUser: () => {
    const token = localStorage.getItem('token');

    var decoded = jwt_decode(token);

    return decoded.user;
  },
  getToken: () => {
    return localStorage.getItem('token');
  },
  getTokenInfo: () => {
    return jwt_decode(localStorage.getItem('token'));
  },
  getPermissionInfo: async () => {
    const token = localStorage.getItem('token');

    var decoded = null;

    if (token) {

      const { resources } = jwt_decode(token);

      decoded = resources;

    }

    return decoded;
  },

  getResourcesByModule: async (moduleName) => {
    const token = localStorage.getItem('token');

    var decoded = null;

    if (token) {

      const { resources } = jwt_decode(token);

      const module = resources.find(res => res.name == moduleName)

      decoded = module.resources;
    }

    return decoded;
  },
  getResources: async () => {
    const token = localStorage.getItem('token');

    var decoded = null;

    if (token) {

      const { resources } = jwt_decode(token);

      decoded = resources;

    }

    return decoded;
  },
  getExtras: async (resourceToFind, moduleName) => {
    const token = localStorage.getItem('token');

    var decoded = null;

    if (token) {

      const { resources } = jwt_decode(token);

      const module = resources.find(res => res.name == moduleName);

      const { extras } = module.resources.find(resource => resource.name === resourceToFind);

      decoded = extras;

    }

    return decoded;
  },
  getPermissions: async (resourceToFind, moduleName) => {
    const token = localStorage.getItem('token');

    var decoded = null;

    if (token) {

      const { resources } = jwt_decode(token);

      const module = resources.find(res => res.name == moduleName);

      const { permission } = module.resources.find(resource => resource.name === resourceToFind);

      decoded = permission;
    }

    return decoded;
  }
}

export default AuthService;
