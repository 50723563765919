import React from 'react';
import AuthService from '../services/AuthService';
import ClientProvider from '../providers/clientProvider';
const Dashboard = React.lazy(() => import('./Shared/dashboard'));
const MainQueryGroup = React.lazy(() => import('./Shared/QueryGroup/mainQueryGroup'));
const MainQuerysWithGroup = React.lazy(() => import('./Shared/QueryGroup/mainQuerysWithGroup'));
const MainCreateQuerysGroup = React.lazy(() => import('./Shared/QueryGroup/mainCreateQuerysGroup'));
const ChangePassword = React.lazy(() => import('./Shared/Password/changePassword'));
const MainReports = React.lazy(() => import('./Shared/Reports/mainReports'));
const MainProviders = React.lazy(() => import('./Shared/Provider/mainProvider'));
const MainClients = React.lazy(() => import('./Shared/Client/mainClient'));
const MainUsers = React.lazy(() => import('./Shared/Users/mainUsers'));
const MainComplaint = React.lazy(() => import('./Complaint/mainComplaint'));
const MainComplaintList = React.lazy(() => import('./Complaint/mainComplaintList'));
const MainComplaintDetail = React.lazy(() => import('./Complaint/mainComplaintDetail'));
const MainContents = React.lazy(() => import('./Shared/Contents/mainContent'));


const constRoutes = [
    { path: '/', exact: true, name: 'Inicio' },
    { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/grupo-consultas', exact: true, name: 'Archivos procesados', component: MainQueryGroup },
    { path: '/grupo-consultas/:id/consultas', exact: true, name: 'Archivos', component: MainQuerysWithGroup },
    { path: '/crear-grupo-consulta', exact: true, name: 'Archivos', component: MainCreateQuerysGroup },
    { path: '/change-password', exact: true, name: 'Cambio de contraseña', component: ChangePassword },
    { path: '/reports', exact: true, name: 'Reportes', component: MainReports },
    { path: '/providers', exact: true, name: 'Proveedores', component: MainProviders },
    { path: '/clients', exact: true, name: 'Clientes', component: MainClients },
    { path: '/contents', exact: true, name: 'Contenido', component: MainContents },
    { path: '/users', exact: true, name: 'Usuarios', component: MainUsers },
    { path: '/complaint', exact: true, name: 'Formulario de denuncia', component: MainComplaint },
    { path: '/complaint/list', exact: true, name: 'Listado de denuncias', component: MainComplaintList },
    { path: '/complaint/:id/detail', exact: true, name: 'Detalle de denuncia', component: MainComplaintDetail },
];

const returnedRoutes = {
    getRoutes: async (client) => {
        let returnedRoutes = [];

        /* Obtenemos los modules */
        let modules = await AuthService.getPermissionInfo();

        if (client) {
            let auxModules = [];
            client.client.modules.forEach(element => {
                const a = modules.find(mod => mod.name == element);
                if (a) {
                    auxModules.push(a);
                }
            });

            modules = auxModules;
        }

        /* Recorremos cada modulo */
        for (const module of modules) {
            /* Si el modulo tiene permiso */
            if (module.access) {
                /* Obtenemos los recursos asociados al modulo */
                const resources = await AuthService.getResourcesByModule(module.name);

                /* Recorremos cada uno de los recursos */
                for (const resource of resources) {
                    /* Recorremos cada una de las rutas asociadas al recurso */
                    for (const route of resource.routes) {
                        /* Buscamos la ruta seleccionada en el arreglo de rutas principal y vemos que sea accesible */
                        let findRoute = constRoutes.find(routes => routes.path === route.name && route.access);

                        /* Si se sumplen las condiciones, agregamos el path al arreglo de rutas devuelto */
                        if (findRoute) {
                            returnedRoutes.push(findRoute);
                        }
                    }
                }
            }
        }

        return returnedRoutes;
    }
}

export default returnedRoutes;