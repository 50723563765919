import API from '../services/ApiService';
import ApiResponseService from '../services/ApiResponseService';

const api = API.create(process.env.REACT_APP_API_URL);

const useForgetPassword = {
    recoveryPassword: async (email) => {
        try {
          const response = await api.forgetPassword(email)

          if (!response.ok) {
              throw new ApiResponseService(response);
          }

          return {
              sendEmail: true,
          };
        } catch (error) {
            return {
              error: error,
            };
        }
      },
}

export default useForgetPassword;
