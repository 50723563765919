import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, FormGroup, Input, Button, Form, Label, FormFeedback, Alert } from 'reactstrap';
import useResetPassword from '../../providers/resetPasswordProvider';
const queryString = require('query-string');

const ResetPassword = (props) => {
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [error, setError] = useState(null);
    const [detailsNotification, setDetailsNotification] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [buttonTitle, setButtonTitle] = useState('Reestablecer');
    const [disabled, setDisabled] = useState(false);

    const submit = async (e) => {
        e.preventDefault();

        const response = await useResetPassword.resetPassword(code, password, passwordConfirmation);

        setDisabled(true);
        setButtonTitle('Procesando...');

        if (response.reset) {
            setDetailsNotification({ color: 'success', message:'Contraseña cambiada exitosamente, redirigiendo en 3 segundos'});
            setShowAlert(true);
            setTimeout(function(){
                props.history.push('/login');
            }, 3000);

        }
        else {
            if (response.error.response.data.code !== 4){
                setShowAlert(true);
                setDetailsNotification({ color: 'danger', message:response.error.message});
            }

            const errorFields = response.error.getFields();

            if (errorFields) {
                setError(response.error);

            }
        }

        setDisabled(false);
        setButtonTitle('Reestablecer');
    }

    useEffect(() => {

        const path = props.location.pathname

        var params = path.split("/")
        var last_var = params.pop();

        if (!last_var) {
            props.history.push('/login');
        }

        setCode(last_var);

    }, [code]);

    const getInputValidation = (field) => {
        let params = {};
        if(error && error.getFields()[field]) {
          params.className = 'form-control form-control-sm is-invalid';
        }
        return params;
    }

    return (
        <Fragment>
            <div className="page-wrapper">
                <Container fluid={true} className="p-0">
                    {/* <!-- Reset Password page start--> */}
                    <div className="authentication-main">
                        <Row>
                            <Col md="12" className="p-0">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center"><img src="http://www.fiscaliaprivada.cl/imagenes/logo.png" alt="" /></div>
                                        <div>
                                            <Alert color={detailsNotification.color} className="dark" isOpen={showAlert} transition={{in: true, timeout: 150}}>
                                                {detailsNotification.message}
                                            </Alert>
                                        </div>
                                        <Card className="mt-4 p-4">
                                            <Form className="theme-form" onSubmit={ e => submit(e)}>
                                                <h5 className="f-16 mb-3 f-w-600">Crea tu nueva contraseña</h5>
                                                <FormGroup>
                                                    <Label className="col-form-label">Nueva contraseña</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        onChange={e => setPassword(e.target.value)}
                                                        value={password}
                                                        placeholder="*****"
                                                        {...getInputValidation('password')} />
                                                        <FormFeedback>{error ? error.getFields()['password'] : ''}</FormFeedback>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="col-form-label">Repite nueva contraseña</Label>
                                                    <Input 
                                                        className="form-control"
                                                        type="password"
                                                        name="password_confirmation"
                                                        id="password_confirmation"
                                                        onChange={e => setPasswordConfirmation(e.target.value)}
                                                        value={passwordConfirmation}
                                                        placeholder="*****"
                                                        {...getInputValidation('password_confirmation')} />
                                                        <FormFeedback>{error ? error.getFields()['password_confirmation'] : ''}</FormFeedback>
                                                </FormGroup>
                                                <FormGroup className="form-row mb-0">
                                                    <Col md="2">
                                                        <Button color="primary" type="submit" disabled={disabled}>{buttonTitle}</Button>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* <!-- Reset Password page end--> */}
                </Container>
            </div>
        </Fragment>
    );
};

export default ResetPassword;