import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';

import {
    Form,
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    FormFeedback
} from 'reactstrap';

import { toast } from 'react-toastify';

import ChangePasswordProvider from '../../../providers/changePasswordProvider';

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [tittleButtonModal, setTittleButtonModal] = useState('Cambiar contraseña');
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(null);

    const submit = async (e) => {
        e.preventDefault();

        setTittleButtonModal('Guardando...');
        setDisabled(true);


        const response = await ChangePasswordProvider.changePassword({
            old_password: oldPassword,
            password: newPassword,
            password_confirmation: repeatNewPassword
        });


        if (response.data) {
            setError(null);
            setTittleButtonModal('Cambiar contraseña');
            setDisabled(false);
            setOldPassword('');
            setNewPassword('');
            setRepeatNewPassword('');
            toast.success(`Contraseña actualizada correctamente`, { position: toast.POSITION.BOTTOM_RIGHT });
        }
        else {
            setTittleButtonModal('Cambiar contraseña');
            setDisabled(false);

            const errorFields = response.error.getFields();

            if (errorFields) {
                setError(response.error);
            }
        }
    }

    const getInputValidation = (field) => {
        let params = {};
        if (error && error.getFields()[field]) {
            params.className = 'form-control is-invalid';
        }
        return params;
    }

    return (
        <Fragment>
            <Breadcrumb parent="Usuario" title="Cambio de contraseña" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Cambiar contraseña</h5>
                            </div>
                            <div className="card-body">
                                <Form onSubmit={e => submit(e)}>
                                    <Row>
                                        <Col md="12">
                                            <Row>
                                                <Col xs="12" sm="12" md="8" lg="6">
                                                    <FormGroup>
                                                        <Label className="col-form-Label">Contraseña actual</Label>
                                                        <div className="input-group">
                                                            <Input
                                                                className="form-control"
                                                                type="password"
                                                                name="oldPassword"
                                                                onChange={e => setOldPassword(e.target.value)}
                                                                value={oldPassword}
                                                                {...getInputValidation('old_password')} />
                                                            <div className="input-group-append"><span className="input-group-text"><i className="icofont icofont-ui-password"></i></span></div>
                                                            <FormFeedback>{error ? error.getFields()['old_password'] : ''}</FormFeedback>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="12">
                                            <Row>
                                                <Col xs="12" sm="12" md="8" lg="6">
                                                    <FormGroup>
                                                        <Label className="col-form-Label">Nueva contraseña</Label>
                                                        <div className="input-group">
                                                            <Input
                                                                className="form-control"
                                                                type="password"
                                                                name="newPassword"
                                                                onChange={e => setNewPassword(e.target.value)}
                                                                value={newPassword}
                                                                {...getInputValidation('password')} />
                                                            <div className="input-group-append"><span className="input-group-text"><i className="icofont icofont-ui-password"></i></span></div>
                                                            <FormFeedback>{error ? error.getFields()['password'] : ''}</FormFeedback>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="12">
                                            <Row>
                                                <Col xs="12" sm="12" md="8" lg="6">
                                                    <FormGroup>
                                                        <Label className="col-form-Label">Repetir nueva contraseña</Label>
                                                        <div className="input-group">
                                                            <Input
                                                                className="form-control"
                                                                type="password"
                                                                name="repeatNewPasswordchange-password"
                                                                onChange={e => setRepeatNewPassword(e.target.value)}
                                                                value={repeatNewPassword}
                                                                {...getInputValidation('password_confirmation')} />
                                                            <div className="input-group-append"><span className="input-group-text"><i className="icofont icofont-ui-password"></i></span></div>
                                                            <FormFeedback>{error ? error.getFields()['password_confirmation'] : ''}</FormFeedback>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="12">
                                            <Button id='titleButton' color="success" disabled={disabled} type="submit">{tittleButtonModal}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ChangePassword;