import React, { Fragment } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { Key, LogOut } from 'react-feather';
import AuthService from '../../../services/AuthService';
import { useHistory } from "react-router-dom";
import ChangePassword from '../../../Modules/Shared/Password/changePassword';


const UserMenu = () => {
    let history = useHistory();

    const logout = async (e) => {

        const response = await AuthService.logout();

        if (response.logout) {
            history.push('/login');
        }
    }

    const changePassword = () => {
        history.push('/change-password');
    }

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man} alt="header-user" />
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><a href="#" onClick={changePassword}><Key /> Cambiar contraseña</a></li>
                    <hr />
                    <li><a href="#" onClick={logout}><LogOut /> Cerrar sesión</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;