export class ConfigDB {
	static data = {
		"settings": {
			"layout_type":"ltr",
			"sidebar": {
				"wrapper":null,
				"bodyWrapper":null
			},
			"sidebar_setting":"default-sidebar",
			"sidebar_backround":"dark-sidebar"
		},
		"color":{
			"layout_version":"light",
			"color":"color-6",
			"primary_color":"#3949ab",
			"secondary_color":"#4fc3f7",
			"mix_layout":"default"
		},
		"router_animation":"fadeIn"
	}
}

export default ConfigDB;