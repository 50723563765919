import API from '../services/ApiService';
import ApiResponseService from '../services/ApiResponseService';

const api = API.create(process.env.REACT_APP_API_URL);

const client = {
    create : async (clientFields) => {
        try {
            const response = await api.createClient(clientFields)

            if (!response.ok) {
                throw new ApiResponseService(response);
            }

            return {
                client: response.data.data.client,
            };
        }
        catch (error) {
            return {
                error: error,
            };
        }
    },
    list: async (filters) => {
        try {
            const response = await api.listClients(filters)

            if (!response.ok) {
                throw new ApiResponseService(response);
            }

            return {
                clients: response.data.data.clients,
                pagination: response.data.data.pagination
            };
        }
        catch (error) {
            return {
                error: error,
            };
        }
    },

    listAssignLawyers: async (clientUuid) => {
        try {
            const response = await api.listClientLawyers(clientUuid)

            if (!response.ok) {
                throw new ApiResponseService(response);
            }

            return {
                lawyers: response.data.data.lawyers,
            };
        }
        catch (error) {
            return {
                error: error,
            };
        }
    },
    edit: async (uuid, clientFields) => {
        try {
            const response = await api.editClient(uuid, clientFields)

            if (!response.ok) {
                throw new ApiResponseService(response);
            }

            return {
                client: response.data.data.client,
            };
        }
        catch (error) {
            return {
                error: error,
            };
        }
    },
    get: async (uuid) => {
        try {
            const response = await api.getClient(uuid)

            if (!response.ok) {
                throw new ApiResponseService(response);
            }

            return {
                client: response.data.data.client,
            };
        }
        catch (error) {
            return {
                error: error,
            };
        }
    },
    delete: async (uuid) => {
        try {
            const response = await api.deleteClient(uuid)

            if (!response.ok) {
                throw new ApiResponseService(response);
            }

            return true;
        }
        catch (error) {
            return {
                error: error,
            };
        }
    }
}

export default client;
