import React, { useState } from 'react';
import AuthService from '../../services/AuthService';
import { Alert, Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label, Button, Badge } from 'reactstrap';

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [detailsNotification, setDetailsNotification] = useState({});
    const [countAttemptsToLogin, setAttempstToLogin] = useState(0);
    const [showAttemptsToLogin, setShowAttempstToLogin] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [titleButton, setTitleButton] = useState('Iniciar sesión');
    const [disable, setDisable] = useState(false);

    const submit = async (e) => {
        e.preventDefault();

        setTitleButton('Verificando...');
        setDisable(true);

        const response = await AuthService.login(email, password);

        if (response.loggedIn) {

            const roleName = AuthService.getRoleName();
            if (roleName == 'Abogado')
            {
                window.open("complaint/list", "_self");
            } else {
                window.open("grupo-consultas", "_self");
            }

        }
        else {
            setShowAlert(true);
            setDetailsNotification({ message: response.error.message });
            setTitleButton('Iniciar sesión');
            setDisable(false);

            const errorFields = response.error.getFields();

            if (errorFields && !errorFields.attempts_count) {
                setShowAttempstToLogin(false);
            }
            else if (errorFields && errorFields.attempts_count) {
                setAttempstToLogin(parseInt(errorFields.attempts_count));
                setShowAttempstToLogin(true);
            }
            else {
                setShowAttempstToLogin(false);
            }
        }
    }

    return (
        <div>
            <div className="page-wrapper">
                <Container fluid={true} className="p-0">
                    <div className="authentication-main">
                        <Row>
                            <Col md="12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div>
                                            <Alert color="danger" className="dark" isOpen={showAlert} transition={{ in: true, timeout: 150 }}>
                                                {detailsNotification.message}
                                            </Alert>
                                        </div>
                                        <Card className="mt-4" style={{ borderRadius: '0px' }}>
                                            <CardBody>
                                                <div className="text-center">
                                                    <div className="text-center"><img src="http://www.fiscaliaprivada.cl/imagenes/logo.png" alt="" /></div>
                                                </div>
                                                <Form className="theme-form" onSubmit={e => submit(e)}>
                                                    <FormGroup>
                                                        <Label className="col-form-Label pt-0">Email</Label>
                                                        <div className="input-group">
                                                            <Input
                                                                className="form-control"
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                required
                                                                onChange={e => setEmail(e.target.value)}
                                                                placeholder="Email"
                                                                value={email} />
                                                            <div className="input-group-append"><span className="input-group-text"><i className="icofont icofont-user"></i></span></div>
                                                        </div>

                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="col-form-Label">Contraseña</Label>
                                                        <div className="input-group">
                                                            <Input
                                                                className="form-control"
                                                                type="password"
                                                                name="password"
                                                                id="password"
                                                                required
                                                                onChange={e => setPassword(e.target.value)}
                                                                placeholder="Contraseña"
                                                                value={password} />
                                                            <div className="input-group-append"><span className="input-group-text"><i className="icofont icofont-ui-password"></i></span></div>
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Row className="mx-auto">
                                                            <Col md="5">
                                                                <Button color="primary" block={false} type="submit" disabled={disable}>{titleButton} <i className="fa fa-sign-in" style={{ fontSize: '16px' }} /></Button>
                                                            </Col>
                                                            <Col md="7" className="my-auto">
                                                                {showAttemptsToLogin ?
                                                                    <div className="float-right">Te quedan <Badge color="warning" pill>{3 - countAttemptsToLogin}</Badge> intentos</div> :
                                                                    null
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Form>
                                                <div className="float-right">
                                                    <a href="/forget-password"> Olvidaste tu contraseña?</a>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Login;