import React, { Fragment } from 'react';
import AuthService from '../../../services/AuthService';

const UserPanel = () => {

    const userData = AuthService.getUser();

    const roleName = AuthService.getRoleName();

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <h6 className="mt-3 f-14">{userData.first_name} {userData.last_name}</h6>
                <p>{roleName}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;