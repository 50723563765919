import apisauce from 'apisauce';
import AuthService from '../services/AuthService';

const create = (baseURL) => { // home
    const api = apisauce.create({
        baseURL,
        headers: {
            Accept: 'application/json',
        }
    });

    api.addAsyncRequestTransform(request => async () => {
        const token = AuthService.getToken();

        if (token) {
            request.headers['Authorization'] = 'Bearer ' + token;
        }

    });

    api.addMonitor(response => {
        if (!response.ok) {
            if ((response.status === 400 && response.data.code === 7)) {
                alert('La autenticación ha expirado, se cerrara la sesión');
                AuthService.logout();
                window.open('/login', "_self");
            }
        }
    });

    if (true) {
        api.addMonitor();
    }

    const login = (email, password) => {
        return api.post('/auth', { email, password });
    };

    const forgetPassword = (email) => {
        return api.post('/recovery-password', { email });
    };

    const resetPassword = (code, password, password_confirmation) => {
        return api.post('/reset-password', { code, password, password_confirmation });
    }

    const changePassword = (data) => {
        return api.post('/change-password', data);
    }

    // Terms Condition Fiscalia

    const editContent = (domain, contentField) => {
        return api.patch(`client/${domain}/content`, {content: contentField});
    }

    const getContent = (domain) => {
        return api.get(`client/${domain}/content`);
    }

    //------ CLIENT CRUD ------------------------------------------------

    const createClient = (clientField) => {
        return api.post('/client', clientField);
    }

    const listClients = (filters) => {
        return api.get('/client', filters);
    }

    const listClientLawyers = (clientUuid) => {
        return api.get(`/client/${clientUuid}/lawyers`);
    }

    const editClient = (uuid, clientField) => {
        return api.patch(`client/${uuid}`, clientField);
    }

    const getClient = (uuid) => {
        return api.get(`client/${uuid}`);
    }

    const deleteClient = (uuid) => {
        return api.delete(`client/${uuid}`)
    }

    //------ LAWYERS VISIBILITY ------------------------------------------------

    const editLawyers = (uuid, lawyersField) => {
        return api.patch(`/complaint-form-answer/${uuid}/visibility`, lawyersField);
    }

    //-------------------------------------------------------------------------
    //-------- QUERY GROUP ----------------------------------------------------
    const createQueryGroup = (data) => {
        return api.post('/query-group', data);
    }

    const listQueryGroup = (filters) => {
        return api.get('/query-group', filters);
    }

    const getQueryGroup = (uuid, query, config) => {
        return api.get(`/query-group/${uuid}`, query, config);
    }

    const uploadQueryGroupResponse = (uuid, file) => {
        return api.post(`query-group/${uuid}/upload-response`, file, {
            headers: {
                Accept: 'application/x-www-form-urlencoded',
            }
        })
    }

    const uploadQueryGroupComercialResponse = (uuid, file) => {
        return api.post(`query-group/${uuid}/upload-comercial-response`, file, {
            headers: {
                Accept: 'application/x-www-form-urlencoded',
            }
        })
    }

    const downloadQueryGroup = (uuid, query, config) => {
        return api.get(`query-group/${uuid}/download`, query, config)
    }

    const downloadQueryGroupResponse = (uuid, query, config) => {
        return api.get(`query-group/${uuid}/download-response`, query, config)
    }

    //--------------------------------------------------------------------------
    //-------------PROVIDERS----------------------------------------------------
    const listProviders = (filters) => {
        return api.get('/provider', filters);
    }

    const getProvider = (uuid) => {
        return api.get(`/provider/${uuid}`)
    }

    const editProvider = (uuid, providerField) => {
        return api.patch(`provider/${uuid}`, providerField);
    }

    //--------------------------------------------------------------------------
    //-------------LAWYERS----------------------------------------------------
    const listLawyers = (filters) => {
        return api.get('/lawyer', filters);
    }

    const getLawyer = (uuid) => {
        return api.get(`/lawyer/${uuid}`)
    }

    const editLawyer = (uuid, providerField) => {
        return api.patch(`lawyer/${uuid}`, providerField);
    }

    //----------------------------------------------------------------------------
    //--------------------------- REPORTS ----------------------------------------
    const getReport = (query, config) => {
        return api.get(`report`, query, config)
    }
    //----------------------------------------------------------------------------
    //------ USER CRUD ------------------------------------------------

    const createUser = (data) => {
        return api.post('/user', data);
    }

    const listUsers = (filters) => {
        return api.get('/user', filters);
    }

    const editUser = (uuid, userField) => {
        return api.patch(`user/${uuid}`, userField);
    }

    const getUser = (uuid) => {
        return api.get(`user/${uuid}`);
    }

    const deleteUser = (uuid) => {
        return api.delete(`user/${uuid}`)
    }
    //----------------------------------------------------------------------------
    //------ USER CLIENT ------------------------------------------------

    const modifyUserToClient = (clientId, userid) => {
        return api.put(`client/${clientId}/user`, { user_id: userid });
    }

    const getUserClients = (userUuid) => {
        return api.get(`user/${userUuid}/clients`);
    }

    //----------------------------------------------------------------------------
    //------ USER PROVIDER ------------------------------------------------
    const createUserToprovider = (uuid, userField) => {
        return api.post(`provider/${uuid}/user`, userField);
    }

    //----------------------------------------------------------------------------
    //------ QUERY GROUP USER ------------------------------------------------
    const editQueryGroupUser = (uuid, userId) => {
        return api.patch(`query-group/${uuid}/user`, { user_id: userId });
    }

    /* ROLE */
    const listRoles = () => {
        return api.get(`role`);
    }


    const getComplaintForm = (clientUuid) => {
        return api.get(`client/${clientUuid}/complaint-form?inflators=groups,questions`);
    }

    /* CREATE QUESTIONS FROM COMPLAINT FORM GROUP */
    const createComplaintFormGroup = (complaintFormUuid, data) => {
        return api.post(`complaint-form/${complaintFormUuid}/complaint-form-group`, data);
    }

    const editComplaintFormGroup = (uuid, data) => {
        return api.patch(`complaint-form-group/${uuid}`, data);
    }

    const deleteComplaintFormGroup = (uuid) => {
        return api.delete(`complaint-form-group/${uuid}`);
    }

    /* QUESTION TYPE */
    const listQuestionType = () => {
        return api.get(`question-type`);
    }

    /* LIST QUESTIONS FROM COMPLAINT FORM GROUP */
    const listQuestionFromGroup = (complaintFormUuid) => {
        return api.get(`complaint-form-group/${complaintFormUuid}/question`);
    }

    /* CREATE QUESTIONS FROM COMPLAINT FORM GROUP */
    const createQuestionFromGroup = (complaintFormUuid, data) => {
        return api.post(`complaint-form-group/${complaintFormUuid}/question`, data);
    }

    /* CREATE QUESTIONS FROM COMPLAINT FORM GROUP */
    const deleteQuestion = (questionUuid) => {
        return api.delete(`question/${questionUuid}`);
    }

    /* CREATE QUESTIONS FROM COMPLAINT FORM GROUP */
    const editQuestion = (questionUuid, data) => {
        return api.patch(`question/${questionUuid}`, data);
    }

    const listComplaintFormAnswers = (data) => {
        return api.get(`complaint-form-answer`, data);
    }

    const getComplaintFormAnswers = (uuid) => {
        return api.get(`complaint-form-answer/${uuid}`);
    }

    const postComplaintFormAnswerMessage = (uuid, message) => {
        return api.post(`complaint-form-answer/${uuid}/message`, {message: message});
    }

    const editComplaintAnswerState = (uuid, state) => {
        return api.patch(`complaint-form-answer/${uuid}/state`, {state: state});
    }

    return {
        changePassword,
        createClient,
        createComplaintFormGroup,
        createQueryGroup,
        createQuestionFromGroup,
        createUser,
        createUserToprovider,
        deleteClient,
        deleteComplaintFormGroup,
        deleteQuestion,
        deleteUser,
        downloadQueryGroup,
        downloadQueryGroupResponse,
        editClient,
        editComplaintAnswerState,
        editComplaintFormGroup,
        editContent,
        editLawyers,
        editProvider,
        editQueryGroupUser,
        editQuestion,
        editUser,
        forgetPassword,
        getClient,
        getComplaintForm,
        getComplaintFormAnswers,
        getContent,
        getProvider,
        getQueryGroup,
        getReport,
        getUser,
        getUserClients,
        listClientLawyers,
        listClients,
        listComplaintFormAnswers,
        listLawyers,
        listProviders,
        listQueryGroup,
        listQuestionFromGroup,
        listQuestionType,
        listRoles,
        listUsers,
        login,
        modifyUserToClient,
        postComplaintFormAnswerMessage,
        resetPassword,
        uploadQueryGroupComercialResponse,
        uploadQueryGroupResponse,
    }
};

export default {
    create
}
