import React from 'react';

const RightSidebar = () => {
        return (
            <div>
                <div className="right-sidebar" id="right_side_bar">
                    <div className="container p-0">
                        <div className="modal-header p-l-20 p-r-20">
                            <div className="col-sm-8 p-0">
                                <h6 className="modal-title font-weight-bold">FRIEND LIST</h6>
                            </div>
                            <div className="col-sm-4 text-right p-0"><i className="mr-2" data-feather="settings"></i></div>
                        </div>
                    </div>
                    <div className="friend-list-search mt-0">
                        <input type="text" placeholder="search friend" /><i className="fa fa-search"></i>
                    </div>
                </div>
            </div>
        )
}

export default RightSidebar; 