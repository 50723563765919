import {
    FileText, FilePlus, Activity, Settings, List, AlignCenter
} from 'react-feather';

import AuthService from '../services/AuthService';

const constOptions = [
    { path: '/grupo-consultas', icon: FileText, title: 'Archivos procesados', type: 'link' },
    { path: '/crear-grupo-consulta', icon: FilePlus, title: 'Nuevo archivo', type: 'link' },
    { path: '/contents', icon: AlignCenter, title: 'Términos fiscalía', type: 'link' },
    { path: '/reports', icon: Activity, title: 'Reportes', type: 'link' },
    {
        title: 'Configuración', icon: Settings, type: 'sub', active: false, children: [
            { path: '/clients', title: 'Clientes', type: 'link' },
            { path: '/users', title: 'Usuarios', type: 'link' },
            { path: '/providers', title: 'Proveedores', type: 'link' },
        ]
    },
    {
        title: 'Denuncias / Asistencia Legal', icon: List, type: 'sub', active: false, children: [
            { path: '/complaint', title: 'Formulario', type: 'link' },
            { path: '/complaint/list', title: 'Listado', type: 'link' },
        ]
    }
];

const returnedNavbar = {
    getNavbar: async (client) => {
        let navbar = [];

        /* Obtenemos los modules */
        let modules = await AuthService.getPermissionInfo();

        if (client) {
            let auxModules = [];
            client.client.modules.forEach(element => {
                const a = modules.find(mod => mod.name == element);
                if (a) {
                    auxModules.push(a);
                }
            });

            modules = auxModules;
        }

        /* Recorremos cada modulo */
        for (const module of modules) {
            /* Si el modulo tiene permiso */
            if (module.access) {
                /* Obtenemos los recursos asociados al modulo */
                const resources = await AuthService.getResourcesByModule(module.name);

                /* Recorremos cada uno de los recursos */
                for (const resource of resources) {
                    /* Recorremos cada una de las rutas asociadas al recurso */
                    for (const route of resource.routes) {
                        /* Verificamos si la ruta tiene accesso */
                        if (route.access) {
                            /* Si tiene acceso, vemos si es alguna de estas 3 rutas especiales */
                            if (route.name === '/clients' || route.name === '/users' || route.name === '/providers') {
                                /* Obtengo el padre de estas 3 rutas */
                                const parent = constOptions.find(routes => routes.title === 'Configuración' && routes.type === 'sub');

                                /* Si el registro padre existe */
                                if (parent) {

                                    /* Obtengo el parent ya en el navbar */
                                    const parentInNavbar = navbar.find(routes => routes.title === 'Configuración' && routes.type === 'sub');

                                    /* Busco la ruta de los hijos */
                                    let findRoute = parent.children.find(children => children.path === route.name);

                                    /* Si el registro no existe en el navbar */
                                    if (!parentInNavbar) {
                                        let newParent = Object.assign({}, parent);

                                        newParent.children = [];
                                        newParent.children.push(findRoute);

                                        navbar.push(newParent);
                                    }
                                    else {
                                        parentInNavbar.children = [...parentInNavbar.children, findRoute]
                                    }
                                }
                            }

                            else if (route.name === '/complaint' || route.name === '/complaint/list') {

                                const parent = constOptions.find(routes => routes.title === 'Denuncias / Asistencia Legal' && routes.type === 'sub');


                                if (parent) {


                                    const parentInNavbar = navbar.find(routes => routes.title === 'Denuncias / Asistencia Legal' && routes.type === 'sub');

                                    let findRoute = parent.children.find(children => children.path === route.name);


                                    if (!parentInNavbar) {
                                        let newParent = Object.assign({}, parent);

                                        newParent.children = [];
                                        newParent.children.push(findRoute);

                                        navbar.push(newParent);
                                    }
                                    else {
                                        parentInNavbar.children = [...parentInNavbar.children, findRoute]
                                    }
                                }
                            }

                            else {
                                let findRoute = constOptions.find(routes => routes.path === route.name);
                                if (findRoute) {
                                    navbar.push(findRoute);
                                }
                            }
                        }
                    }
                }
            }
        }

        return navbar;
    }
}

export default returnedNavbar;
