import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, FormGroup, Input, Button, Label, Form, Alert, FormFeedback } from 'reactstrap';
import useForgetPassword from '../../providers/forgetPasswordProvider';
import { useHistory } from "react-router-dom";

const ForgetPassword = () => {
    let history = useHistory();
    const [email, setEmail] = useState("");
    const [detailsNotification, setDetailsNotification] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState(null);
    const [buttonTitle, setButtonTitle] = useState('Reestablecer');
    const [disabled, setDisabled] = useState(false);

    const submit = async (e) => {
        e.preventDefault();

        setDisabled(true);
        setButtonTitle('Verificando...');

        const response = await useForgetPassword.recoveryPassword(email);

        if (response.sendEmail) {
            setDetailsNotification({ color: 'success', message:'Email enviado correctamente, revisa tu bandeja de entrada, redirigiendo en 3 segundos'});
            setShowAlert(true);
            setTimeout(function(){
                history.push('/login');
            }, 3000);
        }
        else {
            if (response.error.response.data.code !== 4){
                setShowAlert(true);
                setDetailsNotification({ color: 'danger', message:response.error.message});
            }

            const errorFields = response.error.getFields();

            if (errorFields) {
                setError(response.error);

            }
        }

        setDisabled(false);
        setButtonTitle('Reestablecer');
    }

    const getInputValidation = (field) => {
        let params = {};
        if(error && error.getFields()[field]) {
          params.className = 'form-control form-control-sm is-invalid';
        }
        return params;
    }

    return (
        <Fragment>
            <div className="page-wrapper">
                <Container fluid={true} className="p-0">
                    {/* <!-- Forget Password page start--> */}
                    <div className="authentication-main">
                        <Row>
                            <Col md="12" className="p-0">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center"><img src="http://www.fiscaliaprivada.cl/imagenes/logo.png" alt="" /></div>
                                        <div>
                                            <Alert color={detailsNotification.color} className="dark" isOpen={showAlert} transition={{in: true, timeout: 150}}>
                                                {detailsNotification.message}
                                            </Alert>
                                        </div>
                                        <Card className="mt-4 p-4">
                                            <Form className="theme-form" onSubmit={ e => submit(e)}>
                                                <h5 className="f-16 mb-1  f-w-600">Resetea tu contraseña</h5>
                                                <FormGroup>
                                                    <Label className="col-form-label">Email</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="email"
                                                        id="email"
                                                        required
                                                        onChange={e => setEmail(e.target.value)}
                                                        value={email}
                                                        {...getInputValidation('email')} />
                                                     <FormFeedback>{error ? error.getFields()['email'] : ''}</FormFeedback>
                                                </FormGroup>
                                                <FormGroup className="form-row mb-0">
                                                    <Col md="2">
                                                        <Button color="primary" type="submit" disabled={disabled}>{buttonTitle}</Button>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* <!-- Forget Password page end--> */}
                </Container>
            </div>
        </Fragment>
    );
};

export default ForgetPassword;