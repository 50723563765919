import API from '../services/ApiService';
import ApiResponseService from '../services/ApiResponseService';

const api = API.create(process.env.REACT_APP_API_URL);

const ChangePasswordProvider = {
    changePassword: async (data) => {
        try {
          const response = await api.changePassword(data)

          if (!response.ok) {
              throw new ApiResponseService(response);
          }

          return {
              data: response.data,
          };
        } catch (error) {
            return {
              error: error,
            };
        }
      },
}

export default ChangePasswordProvider;
