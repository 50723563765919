import API from '../services/ApiService';
import ApiResponseService from '../services/ApiResponseService';

const api = API.create(process.env.REACT_APP_API_URL);

const useResetPassword = {
    resetPassword : async (code, password, password_confirmation) => {
        try {
            const response = await api.resetPassword(code, password, password_confirmation)

            if (!response.ok) {
                throw new ApiResponseService(response);
            }

            return {
                reset: true,
            };
        }
        catch (error) {
            return {
                error: error,
                reset: false,
            };
        }
    }
}

export default useResetPassword;
